/* You can add global styles to this file, and also import other style files */
@import '~@swimlane/ngx-datatable/index.css';
@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

html,
body {
  height: 100%;
  background-image: linear-gradient(to top, #f3e7e9 0%, #e3eeff 99%, #e3eeff 100%);
}
body {
  margin: 0;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

.title {
  text-align: center;
  margin: 0;
  padding: 1rem 0;
  font-family: 'Syne Mono', monospace;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 1.6rem;
}

.link {
  text-decoration: none;
  color: #e8e8e8;
  font-size: 1.2rem;
  padding: 1rem;
}

.active {
  color: #ffffff;
}
